import { LeftOutlined, PlusCircleOutlined, CloudUploadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation, useParams, Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import * as WorshipActions from '@portal/actions/worship';
import * as FileActions from '@portal/actions/file';

import { useReduxState } from '@portal/hooks/useReduxState';
import { translate } from '~/services/i18n';
import { getPagetType } from '@portal/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';
import { getRouteStackPath } from '@portal/config/routes';
import { maskDate } from '@portal/services/masks';
import * as MessageService from '~/services/message';

import PanelContentBreadcrumb from '@portal/components/PanelContentBreadcrumb/PanelContentBreadcrumb';
import AdvancedInput from '@portal/components/AdvancedInput/AdvancedInput';
import AdvancedForm from '@portal/components/AdvancedForm/AdvancedForm';
import AdvancedButton from '@portal/components/AdvancedButton/AdvancedButton';
import AdvancedUploader from '@portal/components/AdvancedUploader/AdvancedUploader';
import AdvancedTextInput from '@portal/components/AdvancedTextInput/AdvancedTextInput';

const initialValues: models.Worship = {
  title: null,
  description: '',
  header: '',
  fromDate: '',
  untilDate: '',
  exhibitionDate: '',
  imageUrl: null,
  videoUrl: '',
  image: null,
};

const CultDetails: React.FC = () => {
  const [form, setForm] = useState(initialValues);
  const dispatch = useDispatch();
  const { worship } = useReduxState();
  const [initialValuesDescription, setInitialValuesDescription] = useState('');
  

  const [pageType] = useState(getPagetType());
  const params = useParams() as { id: string };
  const { pathname } = useLocation();

  const onFormChange = (
    key: string,
    value: string | null | number | undefined
  ) => {
    setForm((prevState: models.Worship) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (worship && worship.detail && pageType === PAGE_TYPE.EDIT) {
      setForm(worship.detail);
      setInitialValuesDescription(worship.detail.description);
      onFormChange(
        'fromDate',
        DateTime.fromISO(worship.detail.fromDate as string).toLocaleString(
          DateTime.DATE_SHORT
        )
      );
      onFormChange(
        'untilDate',
        DateTime.fromISO(worship.detail.untilDate as string).toLocaleString(
          DateTime.DATE_SHORT
        )
      );
      onFormChange(
        'exhibitionDate',
        DateTime.fromISO(worship.detail.exhibitionDate as string).toLocaleString(
          DateTime.DATE_SHORT
        )
      );
    } else {
      setForm(initialValues);
      setInitialValuesDescription(initialValues.description);
    }
  }, [worship.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(WorshipActions.getWorshipDetail(params.id));
    } else {
      dispatch(WorshipActions.cleanWorshipDetails());
    }
  }, [pathname, pageType]);

  const setReportLinkYoutube = (linkYT: string) => {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = linkYT.match(regExp);
    let videoId = null;
    if (match && match[7].length === 11) {
      videoId = match[7];
    }
    if (linkYT.length > 24) {
      if (videoId === null) {
        return MessageService.error(
          'PAGES.PANEL.CULT.DETAILS.ERRORS.VIDEO_URL'
        );
      }
    }
    onFormChange('videoUrl', linkYT);
  };

  const onFormSubmit = async () => {
    let imageUrl;

    if (
      !DateTime.fromFormat(form.fromDate as string, 'dd/mm/yyyy')
        .toJSDate()
        .getTime()
    ) {
      return MessageService.error(
        translate(
          'PAGES.PANEL.CULT.DETAILS.ERRORS.FROM_DATE'
        )
      );
    }

    if (
      !DateTime.fromFormat(form.untilDate as string, 'dd/mm/yyyy')
        .toJSDate()
        .getTime()
    ) {
      return MessageService.error(
        translate('PAGES.PANEL.CULT.DETAILS.ERRORS.UNTIL_DATE')
      );
    }

    if (!form.title) {
      return MessageService.error(
        translate('PAGES.PANEL.CULT.DETAILS.ERRORS.TITLE')
      );
    }

    if (!form.header) {
      return MessageService.error(
        translate('PAGES.PANEL.CULT.DETAILS.ERRORS.HEADER')
      );
    }

    if (!form.description) {
      return MessageService.error(
        translate('PAGES.PANEL.CULT.DETAILS.ERRORS.DESCRIPTION')
      );
    }

    if (form.image) {

      const { extension, name, base64 } = form.image;
      imageUrl = await dispatch(FileActions.create(
        {
          type: 3,
          extension,
          name,
          base64,
          isPrivate: false,
        }
      ));
    }

    if (form?.fromDate && form?.untilDate) {
      const initDate = DateTime.fromFormat(
        form.fromDate as string,
        translate('SHARED.FORMAT_DATE')
      ).toISODate()  || '';

      const finalDate = DateTime.fromFormat(
        form.untilDate as string,
        translate('SHARED.FORMAT_DATE')
      ).toISODate()  || '';

      if (new Date(initDate).getTime() > new Date(finalDate).getTime()) {
        return MessageService.error('APPLICATION.ERRORS.INVALID_DATE');
      }
    }

    const data: models.Worship = {
      title: form.title,
      description: form.description,
      header: form.header,
      imageUrl,
      videoUrl: form.videoUrl,
      fromDate: DateTime.fromFormat(
        form.fromDate as string,
        translate('SHARED.FORMAT_DATE')
      ).toISODate(),
      untilDate: DateTime.fromFormat(
        form.untilDate as string,
        translate('SHARED.FORMAT_DATE')
      ).toISODate(),
      exhibitionDate: DateTime.fromFormat(
        form.exhibitionDate as string,
        translate('SHARED.FORMAT_DATE')
      ).toISODate(),
      
    };

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(WorshipActions.editWorship(params.id, data));
    } else {
      dispatch(WorshipActions.addWorship(data));
    }
  };

  return (
    <div className="cult-details">
      <Container fluid className="details">
        <Row>
          <Col>
            <PanelContentBreadcrumb
              items={[
                {
                  title: translate('PAGES.PANEL.CULT.REPORT.PAGE_TITLE'),
                  url: getRouteStackPath('WORSHIP', 'CULT_REPORT'),
                  active: true,
                },
                {
                  title: translate(
                    `PAGES.PANEL.CULT.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                    }`
                  ),
                  active: false,
                },
              ]}
            />
          </Col>
        </Row>
      </Container>
      <Row>
        <Col>
          <div className="details__title">
            <h3 className="details__title__text">
              {translate(
                `PAGES.PANEL.CULT.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                }`
              )}
            </h3>
            <p className="details__title__description">
              {translate(
                `PAGES.PANEL.CULT.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                }`
              )}
            </p>
          </div>
        </Col>
        <AdvancedForm onSubmit={onFormSubmit}>
          <div className="details__form">
            <Row>
              <Col md={6}>
                <AdvancedInput
                  onChange={(value: string | null) => onFormChange('title', value)}
                  value={form.title}
                  label={translate(
                    'PAGES.PANEL.CULT.DETAILS.ADD.LABELS.TITLE'
                  )}
                />
              </Col>
              <Col md={6}>
                <AdvancedInput
                  value={form.header}
                  onChange={(value: string | null) => onFormChange('header', value)}
                  label={translate(
                    'PAGES.PANEL.CULT.DETAILS.ADD.LABELS.HEADER'
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <AdvancedInput
                  value={form.videoUrl}
                  onChange={(value: string) => {
                    onFormChange('videoUrl', value);
                    setReportLinkYoutube(value);
                  }}
                  label={translate(
                    'PAGES.PANEL.CULT.DETAILS.ADD.LABELS.VIDEO_URL'
                  )}
                />
              </Col>
            </Row>
            
            <Row>
              <Col md={6}>
                <AdvancedUploader
                  onChange={(value: string) => onFormChange('image', value)}
                  value={form.imageUrl}
                  thumb
                >
                  <AdvancedButton
                    text={translate(
                      'PAGES.PANEL.CULT.DETAILS.ADD.LABELS.UPLOAD_IMAGE'
                    )}
                    endIcon={<CloudUploadOutlined />}
                  />
                </AdvancedUploader>
              </Col>
            </Row>

            <Row className="details__title">
              <Col md={6}>
                <AdvancedInput
                  onChange={(value: string) => onFormChange('fromDate', value)}
                  value={maskDate(form && form.fromDate as string)}
                  placeholder={translate(
                    'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.DETAILS.PLACEHOLDER_DATE'
                  )}
                  label={translate(
                    'PAGES.PANEL.CULT.DETAILS.ADD.LABELS.FROM_DATE'
                  )}
                />
              </Col>
              <Col md={6}>
                <AdvancedInput
                  onChange={(value: string) =>
                    onFormChange('untilDate', value)
                  }
                  value={maskDate(form && form.untilDate as string)}
                  placeholder={translate(
                    'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.DETAILS.PLACEHOLDER_DATE'
                  )}
                  label={translate(
                    'PAGES.PANEL.CULT.DETAILS.ADD.LABELS.UNTIL_DATE'
                  )}
                />
              </Col>
              <Col md={6}>
                <AdvancedInput
                  onChange={(value: string) =>
                    onFormChange('exhibitionDate', value)
                  }
                  value={maskDate(form && form.exhibitionDate as string)}
                  placeholder={translate(
                    'PAGES.PANEL.SCHEDULE.REPORT.ADVANCED_FILTER.EXHIBITION_DATE'
                  )}
                  label={translate(
                    'PAGES.PANEL.SCHEDULE.REPORT.ADVANCED_FILTER.EXHIBITION_DATE'
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <br/>
                <p className="details__title__description">
                  {translate(
                    'PAGES.PANEL.CULT.DETAILS.ADD.LABELS.DESCRIPTION'
                  )}
                </p>
                <AdvancedTextInput
                  id="messages-form"
                  initialValue={initialValuesDescription}
                  onChange={(val: string | null) => onFormChange('description', val)}
                />
              </Col>
            </Row>

            <div className="details__form__actions">
              <Row className="details__form__actions__row">
                <Col md="auto">
                  <Link
                    to={getRouteStackPath('WORSHIP', 'CULT_REPORT')}
                  >
                    <AdvancedButton
                      variant="text"
                      text={translate('SHARED.BACK')}
                      startIcon={<LeftOutlined />}
                    />
                  </Link>
                </Col>
                <Col md="auto" className="text-right">
                  <AdvancedButton
                    type="submit"
                    text={translate('SHARED.SAVE')}
                    endIcon={<PlusCircleOutlined />}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </AdvancedForm>
      </Row>
    </div>
  );
};

export default CultDetails;
