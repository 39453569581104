import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  LeftOutlined,
  PlusCircleOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import * as NewsActions from '~/actions/news';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { getRouteStackPath } from '~/config/routes';
import { FileType } from '@portal/enum/newsType';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import { translate } from '~/services/i18n';
import AdvancedUploader from '@portal/components/AdvancedUploader/AdvancedUploader';
import { getPagetType } from '@portal/utils/page';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { useReduxState } from '@portal/hooks/useReduxState';
import { PAGE_TYPE } from '@portal/enum/pageType';
import * as NewsRepository from '@portal/actions/news';
import { FileItem } from '@portal/models/module';
import { Scope } from '@portal/enum/typeEvent';
import { ProfileType } from '@portal/enum/profileType';
import * as MessageService from '@portal/services/message';
import { FileSchedule } from '~/models/module';
import AdvancedTextInput from '@portal/components/AdvancedTextInput/AdvancedTextInput';
import AdvancedCheckbox from '@portal/components/AdvancedCheckbox/AdvancedCheckbox';
import AdvancedSelect from '@portal/components/AdvancedSelect/AdvancedSelect';

const initialValues: models.AddNews = {
  scope: Scope.SYNODAL,
  title: null,
  description: '',
  fileUrl: '',
  date: '',
  type: FileType.IMAGE,
  files: [],
  synodId: null,
};

const NewsDetails: React.FC = (props) => {
  const [form, setForm] = useState(initialValues);
  const [initialValuesDescription, setInitialValuesDescription] = useState('');
  const [pageType] = useState(getPagetType());
  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const { pathname } = useLocation();
  const { news, user, synod } = useReduxState();
  const [fileThumbnail, setFileThumbnail] = useState<FileSchedule | null>();
  const [fileList, setFileList] = useState<models.FileItem[]>([]);
  const [removedList, setRemovedList] = useState<any>([]);
  const [synodCheckBox, setSynodCheckBox] = useState(false);

  const onFormChange = (
    key: string, 
    value: string | null | number | undefined
  ) => {
    setForm((prevState: models.AddNews) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onFormSubmit = () => {
    const files: FileItem[] = [];

    if (!form.title) {
      return MessageService.error(
        translate('PAGES.PANEL.NEWS_ADD.ERRORS.TITLE')
      );
    }

    if (!form.description) {
      return MessageService.error(
        translate('PAGES.PANEL.NEWS_ADD.ERRORS.DESCRIPTION')
      );
    }

    if (!fileThumbnail && pageType === PAGE_TYPE.ADD) {
      return MessageService.error(
        translate('PAGES.PANEL.NEWS_ADD.ERRORS.FILETHUMBNAIL')
      );
    }

    if (fileThumbnail) {
      files.push(
        {
          id: form.files ? form.files.find((item: { file: { thumbnail: boolean; } }) =>
            item.file?.thumbnail)?.file?.id : null,
          name: fileThumbnail?.name,
          type: FileType.IMAGE,
          base64: fileThumbnail?.base64,
          extension: fileThumbnail?.extension,
          thumbnail: true,
          isPrivate: false,
        }
      );
    }

    fileList.map((item: models.FileItem, index) => {
      if (item.base64 !== undefined) {
        files.push({
          name: item.name,
          extension: item.extension,
          type: FileType.IMAGE,
          base64: item.base64,
          isPrivate: false,
          id: form?.files[index]?.file?.id,
          ...(index === 0) && { thumbnail: true },
        });
      }
    });

    const profile = {
      scope: synodCheckBox ? Scope.SYNODAL : Scope.NATIONAL,
      synodId: synodCheckBox ?  form.synodId || news.detail?.synodId : null,
    }

    if (pageType === PAGE_TYPE.EDIT) {
      if (files.length <= 0 ) {
        delete form.files;
        dispatch(NewsRepository.update(params.id, {
          ...form, 
          ...profile,
          excludedFiles: removedList.length === 0 ? null : removedList,
        }));
      } else {
        dispatch(NewsRepository.update(params.id, {
          ...form,
          ...profile,
          excludedFiles: removedList.length === 0 ? null : removedList, 
          files,
        }));
      }
    } else {
      if (user.me?.user.profileType === ProfileType.SYNOD_ADMIN) {
        dispatch(NewsRepository.create({ ...form, files, scope: Scope.SYNODAL, synodId: user.me?.synod?.id }));
      } else {
        dispatch(NewsRepository.create(
          { 
            ...form, 
            ...profile,
            files,
          }));
      }
    }
  };

  const handleSetFileList = (file: models.FileItem) => {
    if (file.uid) {
      setFileList([...fileList, file]);
    }
  };

  const handleRemoveList = (file: any) => {
    if (file.id) {
      setRemovedList([...removedList, file]);
    }
    const foundIndex = fileList.findIndex((o) => o.name === file.name);
    if (foundIndex === -1) {
      return;
    }
    fileList.splice(foundIndex, 1);
    setFileList([...fileList]);
  };

  const setInitialData = () => {
    const data = {
      title: news.detail?.ad?.title,
      description: news.detail?.ad?.description,
      fileUrl: news.detail?.ad?.fileUrl,
      date: news.detail?.ad?.date,
      type: FileType.IMAGE,
      files: news.detail?.files,
      synodId: news.detail?.synodId || '',
      scope: !synodCheckBox ? Scope.NATIONAL : Scope.SYNODAL
    };
    
    if(news.detail?.synodId) {
      setSynodCheckBox(true);
    } else {
      setSynodCheckBox(false);
    }

    setForm(data);
    setInitialValuesDescription(data.description || '');

    const files: FileItem[] = [];
    if (news.detail?.files) {
      news.detail?.files.map((file) => {
        files.push({
          name: file.file.name,
          extension: file.file.extension,
          type: FileType.IMAGE,
          base64: file.file.base64,
          url: file.file.url,
          id: file.id,
          thumbnail: file.file.thumbnail,
        });
      });
    }

    setFileList(files);
  };

  useEffect(() => {
    if (news && news.detail) {
      setInitialData();
    } else {
      setForm(initialValues);
      setInitialValuesDescription(initialValues.description || '');
    }
  }, [news.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(NewsActions.getDetail(params.id));
    } else {
      dispatch(NewsActions.cleanDetails());
      setFileList([]);
    }
  }, [pathname, pageType]);

  return (
    <Container fluid className="details">
      <Row>
        <Col lg={12}>
          <PanelContentHeader
            pageTitle={translate('PAGES.PANEL.NEWS_ADD.REPORT.PAGE_TITLE')}
            pageDescription={translate(
              'PAGES.PANEL.NEWS_ADD.REPORT.PAGE_DESCRIPTION'
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={8}>
          <AdvancedForm onSubmit={onFormSubmit}>
            <div className="details__form">
              <div className="details__form__item">
                <Row>
                  <Col lg={12}>
                    <PanelContentHeader
                      pageTitle={translate('PAGES.PANEL.NEWS_ADD.REPORT.PAGE_IMAGE')}
                      pageDescription={translate('PAGES.PANEL.NEWS_ADD.REPORT.PAGE_IMAGE_DESCRIPTION')}
                    />
                    <Row>
                      <Col lg={5}>
                        <label>
                          {translate(
                            'PAGES.PANEL.NEWS_ADD.REPORT.ADD.LABELS.NEWS_IMAGE_LIST'
                          )}
                        </label>
                        <AdvancedUploader
                          onChange={setFileThumbnail}
                          thumb
                          defaultImage={
                            fileThumbnail?.base64 ||
                            (form &&
                              form.files &&
                              form.files.find((item: { file: { thumbnail: boolean; } }) =>
                                item.file?.thumbnail)?.file?.url)
                          }
                          value={
                            fileThumbnail?.base64 ||
                            (form &&
                              form.files &&
                              form.files.find((item: { file: { thumbnail: boolean; } }) =>
                                item.file?.thumbnail)?.file?.url)
                          }
                        >
                          <div>
                            <AdvancedButton
                              className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add"
                              text={translate(
                                'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.BUTTON_UPLOAD'
                              )}
                              endIcon={<CloudUploadOutlined />}
                            />
                          </div>
                        </AdvancedUploader>
                      </Col>
                      <Col lg={5}>
                        <label>
                          {translate(
                            'PAGES.PANEL.NEWS_ADD.REPORT.ADD.LABELS.NEWS_IMAGE_DETAIL'
                          )}
                        </label>
                        <AdvancedUploader
                          onChange={handleSetFileList}
                          hidePreview
                        >
                          <div className="advanced-file-upload__preview-container">
                            {fileList &&
                              fileList.length > 0 &&
                              fileList.filter((item: any)=> !item.thumbnail).map((item: any, index) => (
                                <div
                                  key={item.uid}
                                  className={index === 0 ? "advanced-file-upload__preview-thumb" : "advanced-file-upload__preview-detail"}
                                >
                                  <img
                                    key={item.id}
                                    className={fileList.length <= 0 ? "advanced-file-upload__preview-thumb__img" : "advanced-file-upload__preview-detail__img"}
                                    src={item.base64 || item.url}
                                    alt="image_preview"
                                  />
                                  <div className="wrapper__close text-right mt-1 mb-2">
                                    <Button
                                      variant="contained"
                                      onClick={() => handleRemoveList(item)}
                                      color="primary"
                                    >
                                      <DeleteOutlined />
                                    </Button>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div>
                            <AdvancedButton
                              className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add"
                              text={translate(
                                'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.BUTTON_UPLOAD_MULTIPLE'
                              )}
                              endIcon={<CloudUploadOutlined />}
                            />
                          </div>
                        </AdvancedUploader>
                      </Col>
                    </Row>       
                  </Col>
                </Row>
              </div>
              
              <div className="details__title">
                  {user.me?.user.profileType === ProfileType.ADMIN && (
                    <div>
                      <Row>
                        <Col md={6}>
                          <AdvancedCheckbox
                            label={translate(
                              'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.ADD_SYNOD'
                            )}
                            value={synodCheckBox}
                            onChange={() => setSynodCheckBox(!synodCheckBox)}
                          />
                        </Col>
                        <Col md={6}>
                          {synodCheckBox && (
                            <AdvancedSelect
                              label={translate(
                                'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.SYNOD'
                              )}
                              value={form.synodId}
                              onChange={(val: string | undefined) =>
                                onFormChange('synodId', val)
                              }
                              options={synod.allSynod}
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>

              <Row>
                <Col lg={12}>
                  <AdvancedInput
                    onChange={(value: string) => onFormChange('title', value)}
                    value={form?.title}
                    label={translate(
                      'PAGES.PANEL.NEWS_ADD.REPORT.ADD.LABELS.TITLE'
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <p className="details__title__description">
                    {translate(
                      'PAGES.PANEL.NEWS_ADD.REPORT.ADD.LABELS.DESCRIPTION'
                    )}
                  </p>
                  <Row>
                    <Col md={12}>
                      <AdvancedTextInput
                        id="news-form"
                        initialValue={initialValuesDescription}
                        onChange={(val: string) => onFormChange('description', val)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="details__form__actions">
                <Row className="details__form__actions__row">
                  <Col md="auto">
                    <Link to={getRouteStackPath('ACTIVITIES', 'NEWS_REPORT')}>
                      <AdvancedButton
                        variant="text"
                        text={translate('SHARED.BACK')}
                        startIcon={<LeftOutlined />}
                      />
                    </Link>
                  </Col>
                  <Col md="auto">
                    <AdvancedButton
                      type="submit"
                      text={translate('SHARED.SAVE')}
                      endIcon={<PlusCircleOutlined />}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </AdvancedForm>
        </Col>
      </Row>
    </Container>
  );
};

export default NewsDetails;
