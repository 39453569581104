import {
  CloudUploadOutlined,
  LeftOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import * as Schedule from '@portal/actions/schedule';
import AdvancedUploader from '@portal/components/AdvancedUploader/AdvancedUploader';
import { useReduxState } from '@portal/hooks/useReduxState';
import * as MessageService from '@portal/services/message';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import { FileSchedule } from '~/models/module';
import { translate } from '~/services/i18n';
import { getPagetType } from '@portal/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';
import { useLocation, useParams, Link } from 'react-router-dom';
import PanelContentBreadcrumb from '@portal/components/PanelContentBreadcrumb/PanelContentBreadcrumb';
import { getRouteStackPath } from '@portal/config/routes';
import { DateTime } from 'luxon';
import { maskDate } from '@portal/services/masks';
import { ProfileType } from '@portal/enum/profileType';
import AdvancedCheckbox from '@portal/components/AdvancedCheckbox/AdvancedCheckbox';
import AdvancedSelect from '@portal/components/AdvancedSelect/AdvancedSelect';
import { getAllSynods } from '@portal/actions/synod';
import { cepMask, removeSpecialChars } from '@portal/utils/string';
import { getAddressByCep } from '@portal/actions/utils';
import { Scope } from '@portal/enum/typeEvent';
import { FileType } from '@portal/enum/newsType';
import AdvancedTextInput from '@portal/components/AdvancedTextInput/AdvancedTextInput';

const initialValues: models.AddSchedule = {
  isMain: false,
  name: '',
  type: 3,
  title: '',
  description: '',
  street: '',
  number: '',
  neighborhood: '',
  zipcode: '',
  city: '',
  state: '',
  complement: '',
  latitude: '',
  longitude: '',
  miscInfo: '',
  email: '',
  phone: '',
  synodId: null,
  mediaUrl: '',
  scope: null,
  files: [],
};

const OffersDetails: React.FC = () => {
  const [form, setForm] = useState(initialValues);
  const [file, setFile] = useState<FileSchedule>();
  const [fileThumbnail, setFileThumbnail] = useState<FileSchedule | null>();
  const [synodCheckBox, setSynodCheckBox] = useState(false);
  const [localCheckBox, setLocalCheckBox] = useState(false);
  const [initialValuesDescription, setInitialValuesDescription] = useState('');
  const dispatch = useDispatch();
  const { schedule, user, synod } = useReduxState();

  const [pageType] = useState(getPagetType());
  const params = useParams() as { id: string };
  const { pathname } = useLocation();

  const onFormChange = (
    key: string,
    value: string | null | number | undefined | boolean
  ) => {
    setForm((prevState: models.AddSchedule) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const getAddress = (zipcode: string) => {
    dispatch(
      getAddressByCep(zipcode, (data) => {
        if (data) {
          onFormChange('neighborhood', data.data.bairro);
          onFormChange('city', data.data.localidade);
          onFormChange('street', data.data.logradouro);
          onFormChange('complement', data.data.complemento);
          onFormChange('zipcode', data.data.cep);
          onFormChange('state', data.data.uf);
        }
      })
    );
  };

  const setReportLinkYoutube = (linkYT: string) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = linkYT.match(regExp);
    let videoId = null;
    if (match && match[7].length === 11) {
      videoId = match[7];
    }
    if (linkYT.length > 24) {
      if (videoId === null) {
        return MessageService.error(
          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.ERRORS.MEDIA_URL'
        );
      }
    }
    onFormChange('mediaUrl', linkYT);
  };

  useEffect(() => {
    dispatch(getAllSynods());
  }, []);

  useEffect(() => {
    if (schedule && schedule.detail) {
      setForm(schedule.detail);
      setInitialValuesDescription(schedule.detail.description)
      onFormChange('zipcode', schedule.detail.address?.zipcode);
      onFormChange('city', schedule.detail.address?.city);
      onFormChange('state', schedule.detail.address?.state);
      onFormChange('street', schedule.detail.address?.street);
      onFormChange('neighborhood', schedule.detail.address?.neighborhood);
      onFormChange('longitude', schedule.detail.address?.latitude);
      onFormChange('latitude', schedule.detail.address?.longitude);
      onFormChange('number', schedule.detail.address?.number);
      onFormChange('complement', schedule.detail.address?.complement);
      if (schedule.detail.startsAtDate) {
        onFormChange(
          'startsAtDate',
          DateTime.fromISO(schedule.detail.startsAtDate as string).toLocaleString(
            DateTime.DATE_SHORT
          )
        );
      }

      if (schedule.detail.endsAtDate) {
        onFormChange(
          'endsAtDate',
          DateTime.fromISO(schedule.detail.endsAtDate as string).toLocaleString(
            DateTime.DATE_SHORT
          )
        );
      }

      if (schedule.detail.exhibitionDate) {
        onFormChange(
          'exhibitionDate',
          DateTime.fromISO(schedule.detail.exhibitionDate as string).toLocaleString(
            DateTime.DATE_SHORT
          )
        );
      }
      if (schedule.detail.scope === Scope.SYNODAL) {
        setSynodCheckBox(true);
      }

      if (schedule.detail.scope === Scope.PAROCHIAL) {
        setLocalCheckBox(true);
      }
    } else {
      setForm(initialValues);
      setInitialValuesDescription(initialValues.description)
      setSynodCheckBox(false);
      setLocalCheckBox(false);
    }
  }, [schedule.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(Schedule.getDetail(params.id));
    } else {
      dispatch(Schedule.cleanDetails());
    }
  }, [pathname, pageType]);

  const onFormSubmit = () => {
    const fileList = [];

    if ((synodCheckBox && !form.synodId) || (localCheckBox && !form.synodId)) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_SYNOD_ID');
    }

    if (!form.title) {
      return MessageService.error(
        translate('PAGES.PANEL.OFFERS.DETAILS.ERRORS.EVENT')
      );
    }

    if (!form.description) {
      return MessageService.error(
        translate(
          'PAGES.PANEL.OFFERS.DETAILS.ERRORS.DESCRIPTION'
        )
      );
    }

    if (
      (form.startsAtDate &&
        !DateTime.fromFormat(form.startsAtDate as string, 'dd/MM/yyyy')
          .toJSDate()
          .getTime()
      )
      || (
        !form.startsAtDate && form.endsAtDate
      )
    ) {
      return MessageService.error(
        translate('PAGES.PANEL.NEW_ACTIVITIES.CAMPAIGN.DETAILS.ERRORS.INIT_DATE')
      );
    }

    if (
      form.endsAtDate &&
      !DateTime.fromFormat(form.endsAtDate as string, 'dd/MM/yyyy')
        .toJSDate()
        .getTime()
    ) {
      return MessageService.error(
        translate('PAGES.PANEL.NEW_ACTIVITIES.CAMPAIGN.DETAILS.ERRORS.END_DATE')
      );
    }

    if (form?.startsAtDate && form?.endsAtDate) {
      const initDate = DateTime.fromFormat(
        form.startsAtDate as string,
        translate('SHARED.FORMAT_DATE')
      ).toISODate() || '';

      const finalDate = DateTime.fromFormat(
        form.endsAtDate as string,
        translate('SHARED.FORMAT_DATE')
      ).toISODate() || '';

      if (new Date(initDate).getTime() > new Date(finalDate).getTime()) {
        return MessageService.error('APPLICATION.ERRORS.INVALID_DATE');
      }
    }

    if (file) {
      fileList.push(
        {
          id: form.files ? form.files.find((item: { file: { thumbnail: boolean; } }) =>
            !item.file?.thumbnail)?.file?.id : null,
          name: file?.name || null,
          type: FileType.IMAGE,
          base64: file?.base64 || null,
          extension: file?.extension || null,
          isPrivate: false,
        }
      );
    }

    if (fileThumbnail) {
      fileList.push(
        {
          id: form.files ? form.files.find((item: { file: { thumbnail: boolean; } }) =>
            item.file?.thumbnail)?.file?.id : null,
          name: fileThumbnail?.name || null,
          type: FileType.IMAGE,
          base64: fileThumbnail?.base64 || null,
          extension: fileThumbnail?.extension || null,
          thumbnail: true,
          isPrivate: false,
        }
      );
    }

    const data: models.AddSchedule = {
      name: form.name,
      type: form.type,
      title: form.title,
      description: form.description,
      mediaUrl: form.mediaUrl,
      email: form.email,
      phone: form.phone,
      miscInfo: form.miscInfo,
      startsAtDate: DateTime.fromFormat(
        form.startsAtDate as string,
        translate('SHARED.FORMAT_DATE')
      ).toISODate(),
      endsAtDate: DateTime.fromFormat(
        form.endsAtDate as string,
        translate('SHARED.FORMAT_DATE')
      ).toISODate(),
      exhibitionDate: DateTime.fromFormat(
        form.exhibitionDate as string,
        translate('SHARED.FORMAT_DATE')
      ).toISODate(),
      synodId: form.synodId,
      address: {
        zipcode: form.zipcode,
        street: form.street,
        neighborhood: form.neighborhood,
        city: form.city,
        state: form.state,
        number: form.number,
        latitude: form.latitude,
        longitude: form.longitude,
      },
      number: '',
      latitude: '',
      longitude: '',
      files: fileList,
    };

    if (pageType === PAGE_TYPE.EDIT) {
      let scope = form.scope;
      let synodId = form.synodId;

      if (form.synodId && !user.me?.synod?.id) {
        if (synodCheckBox) {
          scope = Scope.SYNODAL;
        } else if (localCheckBox) {
          scope = Scope.PAROCHIAL;
        } else {
          synodId = null;
          scope = Scope.NATIONAL;
        }
      }

      dispatch(Schedule.update(params.id, { ...data, scope, synodId }));
    } else {
      if (user.me?.user.profileType === ProfileType.SYNOD_ADMIN) {
        dispatch(Schedule.create({ ...data, synodId: user.me?.synod?.id, scope: Scope.SYNODAL }));
      } else {
        let scope = form.scope;
        let synodId = form.synodId;

        if (form.synodId) {
          if (synodCheckBox) {
            scope = Scope.SYNODAL;
          } else if (localCheckBox) {
            scope = Scope.PAROCHIAL;
          } else {
            synodId = null;
            scope = Scope.NATIONAL;
          }
        } else {
          scope = Scope.NATIONAL;
        }

        dispatch(Schedule.create({ ...data, scope, synodId }));
      }
    }
    dispatch(Schedule.cleanDetails());
  };

  return (
    <div className="schedule-details">
      <Container fluid className="details">
        <Row>
          <Col>
            <PanelContentBreadcrumb
              items={[
                {
                  title: translate(
                    'PAGES.PANEL.OFFERS.TITLE'
                  ),
                  url: '/ofertas/lista',
                  active: true,
                },
                {
                  title: translate(
                    `PAGES.PANEL.OFFERS_DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                    }`
                  ),
                  active: false,
                },
              ]}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="details__title">
              <h3 className="details__title__text">
                {translate(
                  `PAGES.PANEL.OFFERS_DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                  }`
                )}
              </h3>
              <p className="details__title__description">
                {translate(
                  `PAGES.PANEL.OFFERS_DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                  }`
                )}
              </p>
            </div>
            <AdvancedForm onSubmit={onFormSubmit}>
              <div className="details__form">
                {form.type !== 2 && (
                  <Row>
                    <Col md={6}>
                      <label>
                        {translate(
                          'PAGES.PANEL.OFFERS_DETAILS.REPORT.ADD.LABELS.UPLOAD_THUMB'
                        )}
                      </label>
                      <AdvancedUploader
                        onChange={setFileThumbnail}
                        thumb
                        defaultImage={
                          fileThumbnail?.base64 ||
                          (form &&
                            form.files &&
                            form.files.find((item: { file: { thumbnail: boolean; } }) =>
                              item.file?.thumbnail)?.file?.url)
                        }
                        value={
                          fileThumbnail?.base64 ||
                          (form &&
                            form.files &&
                            form.files.find((item: { file: { thumbnail: boolean; } }) =>
                              item.file?.thumbnail)?.file?.url)
                        }
                      >
                        <div>
                          <AdvancedButton
                            className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add"
                            text={translate(
                              'PAGES.PANEL.OFFERS_DETAILS.REPORT.ADD.LABELS.BUTTON_UPLOAD'
                            )}
                            endIcon={<CloudUploadOutlined />}
                          />
                        </div>
                      </AdvancedUploader>
                    </Col>
                    <Col md={6}>
                      <label>
                        {translate(
                          'PAGES.PANEL.OFFERS_DETAILS.REPORT.ADD.LABELS.UPLOAD'
                        )}
                      </label>
                      <AdvancedUploader
                        onChange={setFile}
                        defaultImage={
                          file?.base64 ||
                          (form &&
                            form.files &&
                            form.files.find((item: { file: { thumbnail: boolean; } }) =>
                              !item.file?.thumbnail)?.file?.url)
                        }
                        value={
                          file?.base64 ||
                          (form &&
                            form.files &&
                            form.files.find((item: { file: { thumbnail: boolean; } }) =>
                              !item.file?.thumbnail)?.file?.url)
                        }
                      >
                        <div>
                          <AdvancedButton
                            className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add"
                            text={translate(
                              'PAGES.PANEL.OFFERS_DETAILS.REPORT.ADD.LABELS.BUTTON_UPLOAD'
                            )}
                            endIcon={<CloudUploadOutlined />}
                          />
                        </div>
                      </AdvancedUploader>
                    </Col>
                  </Row>
                )}

                <div className="details__title">
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string) =>
                          onFormChange('title', value)
                        }
                        value={form.title}
                        label={translate(
                          'PAGES.PANEL.OFFERS_DETAILS.REPORT.ADD.LABELS.TITLE_OFFERS'
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.MEDIA_URL'
                        )}
                        value={form.mediaUrl}
                        onChange={(val: string) => {
                          onFormChange('mediaUrl', val);
                          setReportLinkYoutube(val);
                        }}
                      />
                    </Col>
                  </Row>

                  <div className="details__title">
                      {user.me?.user.profileType === ProfileType.ADMIN && (
                        <div>
                          <Row>
                            <Col md={6}>
                              <AdvancedCheckbox
                                label={translate(
                                  'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.ADD_SYNOD'
                                )}
                                value={synodCheckBox}
                                onChange={() => setSynodCheckBox(!synodCheckBox)}
                              />
                            </Col>
                            <Col md={6}>
                              {synodCheckBox && (
                                <AdvancedSelect
                                  label={translate(
                                    'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.SYNOD'
                                  )}
                                  value={form.synodId}
                                  onChange={(val: string | undefined) =>
                                    onFormChange('synodId', val)
                                  }
                                  options={synod.allSynod}
                                />
                              )}
                            </Col>
                          </Row>
                        </div>
                      )}
                    </div>

                  {(user.me?.user.profileType === ProfileType.ADMIN && !synodCheckBox) && (
                    <div>
                      <Row>
                        <Col md={6}>
                          <AdvancedCheckbox
                            label={translate(
                              'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.ADD_LOCAL'
                            )}
                            value={localCheckBox}
                            onChange={() => setLocalCheckBox(!localCheckBox)}
                          />
                        </Col>
                        <Col md={6}>
                          {localCheckBox && (
                            <AdvancedSelect
                              label={translate(
                                'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.SYNOD'
                              )}
                              value={form.synodId}
                              onChange={(val: string | undefined) =>
                                onFormChange('synodId', val)
                              }
                              options={synod.allSynod}
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                  <Row>
                    <Col>
                      <br/>
                      <p className="details__title__description">
                        {translate(
                          'PAGES.PANEL.OCCUPATION_AREA.CATEGORY.REPORT.ADD.LABELS.DESCRIPTION'
                        )}
                      </p>
                      <Row>
                        <Col md={12}>
                          <AdvancedTextInput
                            id="offers-form"
                            initialValue={initialValuesDescription}
                            onChange={(val: string) => onFormChange('description', val)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string) =>
                          onFormChange('startsAtDate', value)
                        }
                        value={maskDate(form && form.startsAtDate)}
                        placeholder={translate(
                          'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.DETAILS.PLACEHOLDER_DATE'
                        )}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.DATE_BEGIN'
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string) =>
                          onFormChange('endsAtDate', value)
                        }
                        value={maskDate(form && form.endsAtDate)}
                        placeholder={translate(
                          'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.DETAILS.PLACEHOLDER_DATE'
                        )}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.DATE_END'
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string) =>
                          onFormChange('exhibitionDate', value)
                        }
                        value={maskDate(form && form.exhibitionDate)}
                        placeholder={translate(
                          'PAGES.PANEL.SCHEDULE.REPORT.ADVANCED_FILTER.EXHIBITION_DATE'
                        )}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE.REPORT.ADVANCED_FILTER.EXHIBITION_DATE'
                        )}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="details__title">
                  <p className="details__title__description">
                    {translate(
                      'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.ADDRESS'
                    )}
                  </p>
                  <Row>
                    <Col md={4}>
                      <AdvancedInput
                        onChange={(value: string | null) => {
                          onFormChange('zipcode', value);
                          if (
                            value &&
                            removeSpecialChars(value)?.length === 8
                          ) {
                            getAddress(value);
                          }
                        }}
                        value={cepMask(form && form.zipcode)}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.ZIPCODE'
                        )}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('city', value)
                        }
                        value={form.city}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.CITY'
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('state', value)
                        }
                        value={form.state}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.STATE'
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('street', value)
                        }
                        value={form.street}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.STREET'
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('number', value)
                        }
                        value={form.number}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.NUMBER'
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('neighborhood', value)
                        }
                        value={form.neighborhood}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.NEIGHBORHOOD'
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('complement', value)
                        }
                        value={form.complement}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.COMPLEMENT'
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('latitude', value)
                        }
                        value={form.latitude}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.LAT'
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('longitude', value)
                        }
                        value={form.longitude}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.LONG'
                        )}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="details__form__actions">
                  <Row className="details__form__actions__row">
                    <Col md="auto">
                      <Link
                        to={getRouteStackPath('OFFERS', 'OFFERS_REPORT')}
                      >
                        <AdvancedButton
                          variant="text"
                          text={translate('SHARED.BACK')}
                          startIcon={<LeftOutlined />}
                        />
                      </Link>
                    </Col>
                    <Col md="auto">
                      <AdvancedButton
                        type="submit"
                        text={translate('SHARED.SAVE')}
                        endIcon={<PlusCircleOutlined />}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </AdvancedForm>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OffersDetails;
