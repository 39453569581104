import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '@portal/components/PanelContent/PanelContent';
import { translate } from '@portal/services/i18n';
import OffersReportsReport from './OffersReports/Report/OffersReport';
import OffersReportsDetails from './OffersReports/Details/OffersDetails';
import OffersReceivedDetails from './OffersReceived/Details/OffersReceivedDetails';
import OffersReceivedReport from './OffersReceived/Report/OffersReceivedReport';

import { getPagetType } from '@portal/utils/page';
import { PAGE_TYPE } from '@portal/enum/pageType';

const OffersNavigationStack = () => {
  const location = useLocation();

  return (
    <Switch location={location} key={location.pathname}>
      <Route path={getRouteStackPath('OFFERS', 'OFFERS_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.OFFERS.REPORT.TITLE')}
          pageDescription={translate('PAGES.PANEL.OFFERS.REPORT.DESCRIPTION')}
        >
          <OffersReportsReport />
        </PanelContent>
      </Route>
      <Route path={`${getRouteStackPath('OFFERS', 'OFFERS_DETAILS')}/:id`}>
        <PanelContent
          pageTitle={translate(`PAGES.PANEL.OFFERS_DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
          pageDescription={translate(`PAGES.PANEL.OFFERS_DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
        >
          <OffersReportsDetails />
        </PanelContent>
      </Route>
      <Route path={getRouteStackPath('OFFERS', 'OFFERS_RECEIVED_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.OFFERS_RECEIVED.REPORT.TITLE')}
          pageDescription={translate('PAGES.PANEL.OFFERS_RECEIVED.REPORT.DESCRIPTION')}
        >
          <OffersReceivedReport />
        </PanelContent>
      </Route>
      <Route path={`${getRouteStackPath('OFFERS', 'OFFERS_RECEIVED_DETAILS')}/:id`}>
        <PanelContent
          pageTitle={translate(`PAGES.PANEL.OFFERS_RECEIVED.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
          pageDescription={translate(`PAGES.PANEL.OFFERS_RECEIVED.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
        >
          <OffersReceivedDetails />
        </PanelContent>
      </Route>
    </Switch>);
};

export default OffersNavigationStack;
