import React, {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import 'quill/dist/quill.snow.css';

import Quill from 'quill/core';

import Toolbar from 'quill/modules/toolbar';
import Snow from 'quill/themes/snow';
import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Header from 'quill/formats/header';
import Underline from 'quill/formats/underline';
import Link from 'quill/formats/link';
import List from 'quill/formats/list';
import Strike from 'quill/formats/strike';
import Image from 'quill/formats/image';
import { ColorStyle as Color } from 'quill/formats/color';
import { AlignStyle as Align } from 'quill/formats/align';
import { BackgroundStyle as Background } from 'quill/formats/background';

Quill.register({
  'modules/toolbar': Toolbar,
  'themes/snow': Snow,
  'formats/bold': Bold,
  'formats/italic': Italic,
  'formats/header': Header,
  'formats/link': Link,
  'formats/underline': Underline,
  'formats/list': List,
  'formats/strike': Strike,
  'formats/image': Image,
  'formats/color': Color,
  'formats/align': Align,
  'formats/background': Background,
});

interface IAdvancedTextInput {
  id: string;
  initialValue?: string;
  value?: string;
  onChange: (value: string) => void;
}

const Editor = forwardRef(
  (
    { id, readOnly, onEditorChange }: any,
    ref: any
  ) => {
    const containerRef = useRef(null);
    const onEditorChangeRef = useRef(onEditorChange);

    useLayoutEffect(() => {
      onEditorChangeRef.current = onEditorChange;
    });

    useEffect(() => {
      if (ref.current) ref.current.enable(!readOnly);
    }, [readOnly]);

    useEffect(() => {
      if(ref.current != null) return;

      const container: any = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div')
      );

      const quill = new Quill(editorContainer, {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ header: [] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['link', 'image'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ color: [] }, { background: [] }],
            [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
            ['clean'],
          ],
          history: {
            delay: 2000,
            maxStack: 500,
            userOnly: true,
          },
        },
      });

      ref.current = quill;

      quill.on(Quill.events.TEXT_CHANGE, () => {
        onEditorChangeRef.current?.(quill.root.innerHTML);
      });

      return () => {
        ref.current = null;
        container.innerHTML = '';
      };
    }, []);

    return <div id={id} ref={containerRef}></div>;
  }
);

Editor.displayName = 'Editor';


const AdvancedTextInput = ({ id, value, initialValue, onChange }: IAdvancedTextInput) => {
  const quillRef: any = useRef();
  const disableSetValue = useRef(false);

  const [readOnly] = useState(false);

  useEffect(() => {

    if (quillRef.current) {
      const quill = quillRef.current;
      if (quill.root.innerHTML !== value && value && value.length > 0 && !disableSetValue.current) {
        disableSetValue.current = true;
        const selection = quill.getSelection();
        quill.clipboard.dangerouslyPasteHTML(value || '');
        if (selection) quill.setSelection(quill.getLength(), 0);
      }
    }

  }, [value])

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current;
      if (quill.root.innerHTML !== initialValue) {
        const selection = quill.getSelection();
        quill.clipboard.dangerouslyPasteHTML(initialValue || '');
        if (selection) quill.setSelection(quill.getLength(), 0);
      }
    }
  }, [initialValue])

  return (
    <div style={{ marginBottom: 20 }}>
      <Editor
        id={id}
        ref={quillRef}
        readOnly={readOnly}
        onEditorChange={onChange}
      />
    </div>
  );
};

export default AdvancedTextInput;
