import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { translate } from '~/services/i18n';
import { useDispatch } from 'react-redux';
import AdvancedTextInput from '@portal/components/AdvancedTextInput/AdvancedTextInput';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import { SettingsKey } from '~/enum/AboutPasswordEnum';
import * as AnnouncementActions from '~/actions/announcement';
import { useReduxState } from '@portal/hooks/useReduxState';
import { useLocation, Link } from 'react-router-dom';
import {
  PlusCircleOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';

const formInitialValues: models.AboutDailyPasswordForm = {
  key: SettingsKey.DAILY_PASSWORD_DESCRIPTION,
  value: ''
}

const AboutPasswordsDetails: React.FC = () => {
  const [form, setForm] = useState(formInitialValues);
  const [initialValuesDescription, setInitialValuesDescription] = useState('');
  const { announcement } = useReduxState();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const onFormChange = (key: string, value: any) => {
    setForm((prevState: models.AboutDailyPasswordForm) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
    dispatch(AnnouncementActions.getAboutPassword(SettingsKey.DAILY_PASSWORD_DESCRIPTION));
  }, [pathname]);

  const onFormSubmit = () => {
    if(!form.value) return
    if (!announcement.aboutPassword.id) return dispatch(AnnouncementActions.updateAboutPassword(form))
    dispatch(AnnouncementActions.updateAboutPassword(form, announcement.aboutPassword.id ))
  }

  useEffect(() => {
    if(announcement.aboutPassword === null) return;
    const aboutPassword: models.AboutDailyPasswordForm = {
      key: announcement.aboutPassword.key,
      value: announcement.aboutPassword.value
    }
    setForm(aboutPassword)
    setInitialValuesDescription(aboutPassword.value);
  }, [announcement]);

  return (
    <Container fluid className="details">
      <Row>
        <Col>
          <div className="details__title">
            <h3 className="details__title__text">
              {translate('PAGES.PANEL.ABOUT_PASSWORD.TITLE')}
            </h3>
            <p className="details__title__description">
              {translate('PAGES.PANEL.ABOUT_PASSWORD.DESCRIPTION')}
            </p>
          </div>
          <AdvancedForm onSubmit={onFormSubmit}>
            <div className="details__form">
              <Row>
                <Col md={6}>
                  <br/>
                  <p className="details__title__description">
                    {translate(
                      'PAGES.PANEL.INSTITUTIONAL.FORM.DESCRIPTION'
                    )}
                  </p>
                  <AdvancedTextInput
                    id="about-password-form"
                    initialValue={initialValuesDescription}
                    onChange={(val: string) => onFormChange('value', val)}
                  />
                </Col>
              </Row>
            </div>
            <div className="details__form__actions">
              <Row className="details__form__actions__row">
                <Col md="auto">
                  <AdvancedButton
                    variant="text"
                    text={translate('SHARED.BACK')}
                    startIcon={<LeftOutlined />}
                  />
                </Col>
                <Col md="auto">
                  <AdvancedButton
                    type="submit"
                    text={translate('SHARED.SAVE')}
                    endIcon={<PlusCircleOutlined />}
                  />
                </Col>
              </Row>
            </div>
          </AdvancedForm>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutPasswordsDetails;
