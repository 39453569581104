import { LeftOutlined, PlusCircleOutlined, CloudUploadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import AdvancedUploader from '@portal/components/AdvancedUploader/AdvancedUploader';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';

import { translate } from '~/services/i18n';
import { getPhoneNumber, maskPhone, unmaskField } from '~/services/masks';
import * as MessageService from '~/services/message';
import * as ParishActions from '~/actions/parish';
import * as SynodActions from '~/actions/synod';

import { getRouteStackPath } from '~/config/routes';

import { getPagetType } from '~/utils/page';
import { cepMask } from '@portal/utils/string';
import { phoneFormat, removeSpecialChars } from '@portal/utils/format';

import { PAGE_TYPE } from '~/enum/pageType';

import { useReduxState } from '~/hooks/useReduxState';
import { ProfileType } from '~/enum/profileType';
import AdvancedSelect from '~/components/AdvancedSelect/AdvancedSelect';
import { getAddressByCep } from '~/actions/utils';
import AdvancedTextInput from '@portal/components/AdvancedTextInput/AdvancedTextInput';
import { FileType } from '@portal/enum/newsType';
import { FileSchedule } from '@portal/models/module';
import { validateCellphone } from '@portal/utils/validation';

const formInitialValues: models.Parish = {
  name: '',
  synodId: '',
  preachingPoint: '',
  address: {
    zipcode: '',
    street: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
    complement: '',

    latitude: null,
    longitude: null,
  },
  cellphone: '',
  cellphoneSecond: '',
  email: '',
  facebook: '',
  instagram: '',

  title: '',
  description: '',
  mediaUrl: '',
  files: [],
};

const ParishDetails: React.FC = () => {
  const [form, setForm] = useState(formInitialValues);
  const [initialValuesPreachingPoint, setInitialValuesPreachingPoint] = useState('');
  const [file, setFile] = useState<FileSchedule | null>();
  const [pageType] = useState(getPagetType());

  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const { pathname } = useLocation();

  const { parish, user, synod, utils } = useReduxState();

  const onFormChange = async (key: string, val: any, address?: boolean) => {
    if (address) {
      setForm((prevState: models.Parish) => ({
        ...prevState,
        address: {
          ...prevState.address,
          [key]: val,
        },
      }));
    } else {
      setForm((prevState: models.Parish) => ({ ...prevState, [key]: val }));
    }
  };

  const getAddress = (zipcode: string) => {
    dispatch(
      getAddressByCep(zipcode, (data) => {
        if (data) {
          onFormChange('neighborhood', data.data.bairro);
          onFormChange('city', data.data.localidade);
          onFormChange('street', data.data.logradouro);
          onFormChange('complement', data.data.complemento);
          onFormChange('zipcode', data.data.cep);
          onFormChange('state', data.data.uf);
        }
      })
    );
  };

  useEffect(() => {
    if (parish && parish.detail) {
      setForm(parish.detail);
      setInitialValuesPreachingPoint(parish.detail.preachingPoint || '')
      if (parish.detail?.cellphone) {
        onFormChange('cellphone', getPhoneNumber(parish.detail.cellphone));
      }

      if (parish.detail?.cellphoneSecond) {
        onFormChange('cellphoneSecond', getPhoneNumber(parish.detail.cellphoneSecond));
      }

    } else {
      setForm(formInitialValues);
      setInitialValuesPreachingPoint(formInitialValues.preachingPoint || '')
    }
  }, [parish.detail]);

  useEffect(() => {
    if (utils.list) {
      setForm({
        ...form,
        synodId: form.synodId || user.me?.synod?.id,
        address: {
          ...form.address,
          street: utils.list.logradouro || '',
          neighborhood: utils.list.bairro || '',
          city: utils.list.localidade || '',
          state: utils.list.uf || '',
        },
      });
    }
  }, [utils.list]);

  useEffect(() => {
    dispatch(SynodActions.getAllSynods());

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(ParishActions.getDetail(params.id));
    } else {
      dispatch(ParishActions.cleanDetails());
    }

    if (user.me?.user?.profileType === ProfileType.SYNOD_ADMIN && pageType === PAGE_TYPE.ADD) {
      onFormChange('synodId', user.me?.synod?.id);
    }

  }, [pathname, pageType, user.me?.synod]);

  const onFormSubmit = () => {
    const fileList = [];

    if (!form.name) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_NAME');
    }

    if (!form.email) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_EMAIL');
    }

    if (!form.cellphone || !validateCellphone(removeSpecialChars(form.cellphone))) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_CELLPHONE');
    }

    if (removeSpecialChars(form.cellphoneSecond) && !validateCellphone(removeSpecialChars(form.cellphoneSecond))) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_SECOND_CELLPHONE');
    }

    if (pageType === PAGE_TYPE.EDIT && !removeSpecialChars(form.cellphone)) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_CELLPHONE');
    }
 
    if (user?.detail?.profileType === ProfileType.ADMIN && !form.synodId) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_SYNOD_ID');
    }

    if (!form.address?.zipcode) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_ZIPCODE');
    }

    if (!form.address?.street) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_STREET');
    }

    if (!form.address?.number) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_NUMBER');
    }

    if (!form.address?.city) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_CITY');
    }

    if (!form.address?.state) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_STATE');
    }

    if (file) {
      fileList.push(
        {
          id: form.files ? form.files.find((item: { file: { thumbnail: boolean; } }) =>
            !item.file?.thumbnail)?.file?.id : null,
          name: file?.name || null,
          type: FileType.IMAGE,
          base64: file?.base64 || null,
          extension: file?.extension || null,
          isPrivate: false,
        }
      );
    }

    const payload: any = {
      ...form,
      address: {
        ...form.address,
        ...!form.address.latitude  && {
          latitude: null
        },
        ...!form.address.longitude && {
          longitude: null
        },
      },
      synodId: form.synodId || user.me?.synod?.id,
      zipcode: removeSpecialChars(form.address.zipcode),
      cellphone: `55${unmaskField(form.cellphone)}`,
      cellphoneSecond: unmaskField(form.cellphoneSecond) ? `55${unmaskField(form.cellphoneSecond)}` : '',
      files: fileList,
    };

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(ParishActions.update(parish?.detail?.id, payload));
    } else {
      delete payload.id;

      dispatch(ParishActions.create(payload));
    }
  };

  const getCellPhone = (phoneNumber?: string | null) => {
    let formattedCellphone = '';
    if (typeof phoneNumber === 'string') {
      if (phoneNumber.length === 12) {
        formattedCellphone = maskPhone(phoneNumber.replace(/\D/g, '').slice(-10));
      } else {
        formattedCellphone = maskPhone(phoneNumber.replace(/\D/g, '').slice(-11));
      }
    }

    return formattedCellphone;
  };

  const setReportLinkYoutube = (linkYT: string) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = linkYT.match(regExp);
    let videoId = null;
    if (match && match[7].length === 11) {
      videoId = match[7];
    }
    if (linkYT.length > 24) {
      if (videoId === null) {
        return MessageService.error(
          'PAGES.PANEL.USER.PARISH.REPORT.FORM.ERRORS.MEDIA_URL'
        );
      }
    }
    onFormChange('mediaUrl', linkYT);
  };

  return (
    <Container fluid className="details">
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                title: translate('PAGES.PANEL.USER.PARISH.REPORT.TITLE'),
                url: getRouteStackPath('USER', 'PARISH_REPORT'),
                active: true,
              },
              {
                title: translate(
                  `PAGES.PANEL.USER.PARISH.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                  }`
                ),
                active: false,
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="details__title">
            <h3 className="details__title__text">
              {translate(
                `PAGES.PANEL.USER.PARISH.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                }`
              )}
            </h3>
            <p className="details__title__description">
              {translate(
                `PAGES.PANEL.USER.PARISH.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                }`
              )}
            </p>
          </div>
          <AdvancedForm onSubmit={onFormSubmit}>
            <div className="details__form">
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.NAME.LABEL'
                    )}
                    value={form.name}
                    onChange={(val: string) => onFormChange('name', val)}
                  />
                </Col>
              </Row>

              {user?.me?.user?.profileType === ProfileType.ADMIN && (
                <Row>
                  <Col md={6}>
                    <AdvancedSelect
                      label={translate(
                        'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.SYNOD.LABEL'
                      )}
                      value={form.synodId}
                      onChange={(val: string) => onFormChange('synodId', val)}
                      options={synod.allSynod}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.PHONE.LABEL'
                    )}
                    value={
                      typeof form.cellphone === 'string' &&
                      maskPhone(form.cellphone)
                    }
                    onChange={(val: string) => onFormChange('cellphone', val)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.SECOND_PHONE.LABEL'
                    )}
                    value={
                      typeof form.cellphoneSecond === 'string' &&
                      maskPhone(form.cellphoneSecond)
                    }
                    onChange={(val: string) => onFormChange('cellphoneSecond', val)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.EMAIL.LABEL'
                    )}
                    value={form.email}
                    onChange={(val: string) => onFormChange('email', val)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.FACEBOOK.LABEL'
                    )}
                    value={form.facebook}
                    onChange={(val: string) => onFormChange('facebook', val)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.INSTAGRAM.LABEL'
                    )}
                    placeholder={translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.INSTAGRAM.PLACEHOLDER'
                    )}
                    value={form.instagram}
                    onChange={(val: string) => onFormChange('instagram', val)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div className="details__form__subtitle">
                    <h3>{translate('PAGES.PANEL.USER.PARISH.DETAILS.FORM.TITLES.PREACHING_POINTS')}</h3>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <AdvancedTextInput
                    id="parish-form"
                    initialValue={initialValuesPreachingPoint}
                    onChange={(val: string) => onFormChange('preachingPoint', val)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div className="details__form__subtitle">
                    <h3>{translate('PAGES.PANEL.USER.PARISH.DETAILS.FORM.TITLES.ADDRESS')}</h3>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.ZIPCODE.LABEL'
                    )}
                    value={cepMask(form && form.address?.zipcode)}
                    onChange={(val: string) => {
                      onFormChange('zipcode', val, true);
                      if (val && removeSpecialChars(val)?.length === 8) {
                        getAddress(removeSpecialChars(val));
                      }
                    }}
                    type="tel"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.STREET.LABEL'
                    )}
                    value={form.address?.street}
                    onChange={(val: string) =>
                      onFormChange('street', val, true)
                    }
                  />
                </Col>
                <Col md={2}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.NUMBER.LABEL'
                    )}
                    value={form.address?.number}
                    onChange={(val: string) =>
                      onFormChange('number', val, true)
                    }
                    type="tel"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.NEIGHBORHOOD.LABEL'
                    )}
                    value={form.address?.neighborhood}
                    onChange={(val: string) =>
                      onFormChange('neighborhood', val, true)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.CITY.LABEL'
                    )}
                    value={form.address?.city}
                    onChange={(val: string) => onFormChange('city', val, true)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.STATE.LABEL'
                    )}
                    value={form.address?.state}
                    onChange={(val: string) => onFormChange('state', val, true)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.COMPLEMENT.LABEL'
                    )}
                    value={form.address?.complement}
                    onChange={(val: string) =>
                      onFormChange('complement', val, true)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.LATITUDE.LABEL'
                    )}
                    value={form.address?.latitude}
                    onChange={(val: string) =>
                      onFormChange('latitude', val, true)
                    }
                  />
                </Col>
                <Col md={3}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.LONGITUDE.LABEL'
                    )}
                    value={form.address?.longitude}
                    onChange={(val: string) =>
                      onFormChange('longitude', val, true)
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div className="details__form__subtitle">
                    <h3>{translate('PAGES.PANEL.USER.PARISH.DETAILS.FORM.SUBTITLES.WHERE_ARE_WE.LABEL')}</h3>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.WHERE_ARE_WE.TITLE.LABEL'
                    )}
                    value={form.title}
                    onChange={(val: string) =>
                      onFormChange('title', val)
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.WHERE_ARE_WE.MEDIA_URL.LABEL'
                    )}
                    value={form.mediaUrl}
                    onChange={(val: string) => {
                      onFormChange('mediaUrl', val);
                      setReportLinkYoutube(val);
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.WHERE_ARE_WE.DESCRIPTION.LABEL'
                    )}
                    multiline
                    value={form.description}
                    onChange={(val: string) =>
                      onFormChange('description', val)
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <label>
                    {translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.WHERE_ARE_WE.UPLOAD.LABEL'
                    )}
                  </label>
                  <AdvancedUploader
                    onChange={setFile}
                    defaultImage={
                      file?.base64 ||
                      (form &&
                        form.files &&
                        form.files[0]?.file &&
                        form.files[0]?.file?.url
                      )
                    }
                    value={
                      file?.base64 ||
                      (form &&
                        form.files &&
                        form.files[0]?.file &&
                        form.files[0]?.file?.url)
                    }
                  >
                    <div>
                      <AdvancedButton
                        className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add"
                        text={translate(
                          'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.WHERE_ARE_WE.BUTTON_UPLOAD'
                        )}
                        endIcon={<CloudUploadOutlined />}
                      />
                    </div>
                  </AdvancedUploader>
                </Col>
              </Row>

              <div className="details__form__actions">
                <Row className="details__form__actions__row">
                  <Col md="auto">
                    <Link to={getRouteStackPath('USER', 'PARISH_REPORT')}>
                      <AdvancedButton
                        variant="text"
                        text={translate('SHARED.BACK')}
                        startIcon={<LeftOutlined />}
                      />
                    </Link>
                  </Col>
                  <Col md="auto">
                    <AdvancedButton
                      type="submit"
                      text={translate('SHARED.SAVE')}
                      endIcon={<PlusCircleOutlined />}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </AdvancedForm>
        </Col>
      </Row>
    </Container>
  );
};

export default ParishDetails;
