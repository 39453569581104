import {
  CloudUploadOutlined,
  LeftOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation, useParams, Link } from 'react-router-dom';
import * as OccupationAreaActions from '@portal/actions/occupationArea';
import { EventType, Scope } from '@portal/enum/typeEvent';
import { useReduxState } from '@portal/hooks/useReduxState';
import * as MessageService from '@portal/services/message';
import { translate } from '~/services/i18n';
import { getPagetType } from '@portal/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';
import { getRouteStackPath } from '@portal/config/routes';
import Button from '@material-ui/core/Button';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import PanelContentBreadcrumb from '@portal/components/PanelContentBreadcrumb/PanelContentBreadcrumb';
import AdvancedSelect from '@portal/components/AdvancedSelect/AdvancedSelect';
import AdvancedUploader from '@portal/components/AdvancedUploader/AdvancedUploader';
import { FileSchedule } from '@portal/models/module';
import { FileType } from '@portal/enum/newsType';
import AdvancedTextInput from '@portal/components/AdvancedTextInput/AdvancedTextInput';
import { Divider, Modal } from 'antd';
import { remove } from '../../../../actions/file';
import { openPdf } from '@portal/utils/fileUtils';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AdvancedDrag from '@portal/components/AdvancedDrag/AdvancedDrag';

const initialValues: models.AddOccupationArea = {
  name: '',
  type: EventType.OCCUPATION,
  title: '',
  description: '',
  mediaUrl: '',
  scope: Scope.NATIONAL,
  eventId: null,
  files: [],
  categories: [],
};

const SubCategoryDetails: React.FC = () => {
  const [form, setForm] = useState(initialValues);
  const [initialValuesDescription, setInitialValuesDescription] = useState('');
  const dispatch = useDispatch();
  const { occupationArea } = useReduxState();
  const [file, setFile] = useState<FileSchedule | null>(null);
  const [fileThumbnail, setFileThumbnail] = useState<FileSchedule | null>();
  const [fileList, setFileList] = useState<models.FileItem[]>([]);
  const [removedList, setRemovedList] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [fileToExclude, setFileToExclude] = useState<any | null>();
  const [pageType] = useState(getPagetType());
  const params = useParams() as { id: string };
  const { pathname } = useLocation();

  const onFormChange = (
    key: string,
    value: string | null | number | undefined | boolean
  ) => {
    setForm((prevState: models.AddOccupationArea) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (occupationArea && occupationArea.detail) {
      setForm(occupationArea.detail);
      setInitialValuesDescription(occupationArea.detail.description);
      setFileList(occupationArea.detail.files.filter((item: any) => item.file.type === FileType.PDF).map((item: any) => { return item.file}))
    } else {
      setForm(initialValues);
      setInitialValuesDescription(initialValues.description);
      setFileList([]);
    }
  }, [occupationArea.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(OccupationAreaActions.getDetail(params.id));
    } else {
      dispatch(OccupationAreaActions.cleanDetails());
    }

    dispatch(OccupationAreaActions.getAllCategories());
  }, [pathname, pageType]);

  const onFormSubmit = async () => {
    let data = null;
    const imageList = [];

    if (!form.eventId && pageType === PAGE_TYPE.ADD) {
      return MessageService.error('PAGES.PANEL.OCCUPATION_AREA.SUB_CATEGORY.ERRORS.CATEGORY');
    }

    if (!form.title) {
      return MessageService.error('PAGES.PANEL.OCCUPATION_AREA.SUB_CATEGORY.ERRORS.TITLE');
    }

    if (!form.name) {
      return MessageService.error('PAGES.PANEL.OCCUPATION_AREA.SUB_CATEGORY.ERRORS.NAME');
    }

    if (!form.type) {
      return MessageService.error('PAGES.PANEL.OCCUPATION_AREA.SUB_CATEGORY.ERRORS.TYPE');
    }

    if (!form.description) {
      return MessageService.error('PAGES.PANEL.OCCUPATION_AREA.SUB_CATEGORY.ERRORS.DESCRIPTION');
    }

    if (!file && pageType === PAGE_TYPE.ADD) {
      return MessageService.error('PAGES.PANEL.OCCUPATION_AREA.SUB_CATEGORY.ERRORS.IMAGE');
    }

    if ((form.files && form.files.length <= 1) && pageType === PAGE_TYPE.EDIT) {
      return MessageService.error('PAGES.PANEL.OCCUPATION_AREA.SUB_CATEGORY.ERRORS.IMAGE');
    }

    if (file) {
      imageList.push(
        {
          id: form.files ? form.files.find((item: { file: { thumbnail: boolean; } }) =>
            !item.file?.thumbnail)?.file?.id : null,
          name: file?.name || null,
          type: FileType.IMAGE,
          base64: file?.base64 || null,
          extension: file?.extension || null,
          isPrivate: false,
        }
      );
    }

    if (fileThumbnail) {
      imageList.push(
        {
          id: form.files ? form.files.find((item: { file: { thumbnail: boolean; } }) =>
            item.file?.thumbnail)?.file?.id : null,
          name: fileThumbnail?.name || null,
          type: FileType.IMAGE,
          base64: fileThumbnail?.base64 || null,
          extension: fileThumbnail?.extension || null,
          thumbnail: true,
          isPrivate: false,
        }
      );
    }

    if( fileList && fileList.length > 0) {
      fileList.map((item, index) => {
        imageList.push({
          id: fileList[index].id || null,
          order: index,
          title: fileList[index].title || fileList[index].file.title,
          name: item.name,
          type: FileType.PDF,
          base64: item.base64,
          extension: item.extension,
          isPrivate: false,
        })
      })
    }

    data = {
      ...form,
      categories: [
        {
          id: form.eventId,
        }
      ],
      files: imageList,
    };

    if (pageType === PAGE_TYPE.ADD) {
      dispatch(OccupationAreaActions.create(data));
    }

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(OccupationAreaActions.update(params.id, data));
    }
  };

  const handleOk = async () => {
    if (fileToExclude.id) {
      setRemovedList([...removedList, file]);
      await remove(fileToExclude.id)
    }
    const foundIndex = fileList.findIndex((o) => o.name === fileToExclude.name);
    if (foundIndex === -1) {
      return;
    }
    fileList.splice(foundIndex, 1);
    setFileList([...fileList]);
    setModalOpen(false)
  }

  const handleCancel = () => {
    setModalOpen(false)
  }

  const changeFileTitle = (value: string, index: number, item: any) => {
    item = {
      ...item,
      title: value,
      name: item.name || ''
    }
    const items = Array.from(fileList);
    items.splice(index, 1);
    items.splice(index, 0, item);
    setFileList(items)
  }

  const handleDrag = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(fileList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFileList(items)
  }

  const handleSetFileList = (file: models.FileItem) => {
    if (file.uid) {
      setFileList([...fileList, file]);
    }
  };

  return (
    <div className="schedule-details">
      <Modal 
        visible={modalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <h3>
          {translate('APPLICATION.ERRORS.EXCLUDE_PDF')}
        </h3>
      </Modal>
      <Container fluid className="details">
        <Row>
          <Col>
            <PanelContentBreadcrumb
              items={[
                {
                  title: translate(
                    'PAGES.PANEL.OCCUPATION_AREA.SUB_CATEGORY.REPORT.PAGE_TITLE'
                  ),
                  url: getRouteStackPath('OCCUPATION_AREA', 'SUBCATEGORY_REPORT'),
                  active: true,
                },
                {
                  title: translate(
                    `PAGES.PANEL.OCCUPATION_AREA.SUB_CATEGORY.DETAIL.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                    }`
                  ),
                  active: false,
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="details__title">
              <h3 className="details__title__text">
                {translate(
                  `PAGES.PANEL.OCCUPATION_AREA.SUB_CATEGORY.DETAIL.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                  }`
                )}
              </h3>
              <p className="details__title__description">
                {translate(
                  `PAGES.PANEL.OCCUPATION_AREA.SUB_CATEGORY.DETAIL.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                  }`
                )}
              </p>
            </div>
            <AdvancedForm onSubmit={onFormSubmit}>
              <div className="details__form">
                <div className="details__title">
                  <p className="details__title__description">
                    {translate(
                      'PAGES.PANEL.OCCUPATION_AREA.SUB_CATEGORY.REPORT.ADD.LABELS.TYPE_OCCUPATION'
                    )}
                  </p>
                  {
                    pageType === PAGE_TYPE.ADD && occupationArea.all.length > 0 && (
                      <Row>
                        <Col>
                          <AdvancedSelect
                            label={translate(
                              'PAGES.PANEL.OCCUPATION_AREA_DETAILS.REPORT.ADD.LABELS.SELECT_CATEGORY'
                            )}
                            value={form.categories?.[0]?.categoryId}
                            onChange={(val: string) => {
                              onFormChange('eventId', val);
                            }}
                            options={occupationArea.all}
                          />
                        </Col>
                      </Row>
                    )
                  }
                  {pageType === PAGE_TYPE.EDIT &&
                    (occupationArea.all.length > 0 && form.categories && form.categories.length > 0) && (
                      <Row>
                        <Col>
                          <AdvancedSelect
                            label={translate(
                              'PAGES.PANEL.OCCUPATION_AREA_DETAILS.REPORT.ADD.LABELS.SELECT_CATEGORY'
                            )}
                            value={form.eventId || form.categories?.[0]?.categoryId}
                            onChange={(val: string) => {
                              onFormChange('eventId', val);
                            }}
                            options={occupationArea.all}
                          />
                        </Col>
                      </Row>
                    )}
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('title', value)
                        }
                        value={form.title}
                        label={translate(
                          'PAGES.PANEL.OCCUPATION_AREA_DETAILS.REPORT.ADD.LABELS.TITLE'
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        label={translate(
                          'PAGES.PANEL.OCCUPATION_AREA.SUB_CATEGORY.REPORT.ADD.LABELS.NAME_OCCUPATION'
                        )}
                        value={form.name}
                        onChange={(val: string) => onFormChange('name', val)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <label>
                        {translate(
                          'PAGES.PANEL.OCCUPATION_AREA_DETAILS.REPORT.ADD.LABELS.UPLOAD_THUMB'
                        )}
                      </label>
                      <AdvancedUploader
                        thumb
                        onChange={setFileThumbnail}
                        defaultImage={
                          fileThumbnail?.base64 ||
                          (form &&
                            form.files &&
                            form.files.find((item: { file: { thumbnail: boolean; } }) =>
                              item.file?.thumbnail)?.file?.url)
                        }
                        value={
                          fileThumbnail?.base64 ||
                          (form &&
                            form.files &&
                            form.files.find((item: { file: { thumbnail: boolean; } }) =>
                              item.file?.thumbnail)?.file?.url)
                        }
                      >
                        <div>
                          <AdvancedButton
                            className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add"
                            text={translate(
                              'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.BUTTON_UPLOAD'
                            )}
                            endIcon={<CloudUploadOutlined />}
                          />
                        </div>
                      </AdvancedUploader>
                    </Col>
                    <Col md={6}>
                      <label>
                        {translate(
                          'PAGES.PANEL.OCCUPATION_AREA_DETAILS.REPORT.ADD.LABELS.UPLOAD'
                        )}
                      </label>
                      <AdvancedUploader
                        onChange={setFile}
                        defaultImage={
                          file?.base64 ||
                          (form &&
                            form.files &&
                            form.files.find((item: { file: { thumbnail: boolean; } }) =>
                              !item.file?.thumbnail)?.file?.url)
                        }
                        value={
                          file?.base64 ||
                          (form &&
                            form.files &&
                            form.files.find((item: { file: { thumbnail: boolean; } }) =>
                              !item.file?.thumbnail)?.file?.url)
                        }
                      >
                        <div>
                          <AdvancedButton
                            className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add"
                            text={translate(
                              'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.BUTTON_UPLOAD'
                            )}
                            endIcon={<CloudUploadOutlined />}
                          />
                        </div>
                      </AdvancedUploader>
                    </Col>
                  </Row>
                </div>

                <div className="details__title">
                  <Row>
                    <Col md={12}>
                      <div className="details__title">
                        <p className="details__title__description">
                          {translate(
                            'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.DESCRIPTION'
                          )}
                        </p>
                        <Row>
                          <Col md={12}>
                            <AdvancedTextInput
                              id="password-form"
                              initialValue={initialValuesDescription}
                              onChange={(val: string) => onFormChange('description', val)}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div>
                  <AdvancedUploader
                    hidePreview
                    isPdf
                    onChange={handleSetFileList}
                  >
                    <div style={{ marginTop: '35px', marginBottom: '35px' }}>
                      <AdvancedButton
                        className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add"
                        text={translate(
                          'PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.ADD.LABELS.FILE_URL'
                        )}
                        endIcon={<CloudUploadOutlined />}
                      />
                    </div>
                  </AdvancedUploader>
                </div>

                <AdvancedDrag
                  setModalOpen={setModalOpen}
                  modalOpen={modalOpen}
                  setFileToExclude={setFileToExclude}
                  setFileList={setFileList}
                  fileList={fileList}
                  handleOk={handleOk}
                  handleCancel={handleCancel}
                />

                <div className="details__form__actions">
                  <Row className="details__form__actions__row">
                    <Col md="auto">
                      <Link
                        to={getRouteStackPath('OCCUPATION_AREA', 'SUBCATEGORY_REPORT')}
                      >
                        <AdvancedButton
                          variant="text"
                          text={translate('SHARED.BACK')}
                          startIcon={<LeftOutlined />}
                        />
                      </Link>
                    </Col>
                    <Col md="auto">
                      <AdvancedButton
                        type="submit"
                        text={translate('SHARED.SAVE')}
                        endIcon={<PlusCircleOutlined />}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </AdvancedForm>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SubCategoryDetails;
