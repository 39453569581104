import { PlusCircleOutlined, LeftOutlined, LinkOutlined, CloudUploadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation, useParams, Link } from 'react-router-dom';

import * as WorshipContentActions from '@portal/actions/worshipContent';
import { useReduxState } from '@portal/hooks/useReduxState';
import { translate } from '~/services/i18n';
import { getPagetType } from '@portal/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';
import { getRouteStackPath } from '@portal/config/routes';

import PanelContentBreadcrumb from '@portal/components/PanelContentBreadcrumb/PanelContentBreadcrumb';
import AdvancedInput from '@portal/components/AdvancedInput/AdvancedInput';
import AdvancedForm from '@portal/components/AdvancedForm/AdvancedForm';
import AdvancedButton from '@portal/components/AdvancedButton/AdvancedButton';
import AdvancedUploader from '@portal/components/AdvancedUploader/AdvancedUploader';
import * as MessageService from '~/services/message';
import AdvancedTextInput from '@portal/components/AdvancedTextInput/AdvancedTextInput';

const initialValues: models.WorshipContent = {
  title: '',
  content: '',
  author: '',
  reference: '',
  number: null,
  translation: '',
  type: 2,
  fileId: '',
  file: null,
};

const LiturgyDetails: React.FC = () => {
  const [form, setForm] = useState(initialValues);
  const [file, setFile] = useState<any>(null);
  const [initialValuesContent, setInitialValuesContent] = useState('');

  const dispatch = useDispatch();
  const { worshipContent } = useReduxState();

  const [pageType] = useState(getPagetType());
  const params = useParams() as { id: string };
  const { pathname } = useLocation();

  const onFormChange = (
    key: string,
    value: string | null | number | undefined
  ) => {
    setForm((prevState: models.WorshipContent) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (worshipContent && worshipContent.detail) {
      setForm(worshipContent.detail);
      setInitialValuesContent(worshipContent.detail.content)
      setFile(worshipContent.detail.file);
    } else {
      setForm(initialValues);
      setInitialValuesContent(initialValues.content)
      setFile(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worshipContent.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(WorshipContentActions.getWorshipContentDetail(params.id));
    } else {
      dispatch(WorshipContentActions.cleanWorshipContentDetails());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, pageType]);

  const onFormSubmit = async () => {
    if (!form.title) {
      return MessageService.error(
        'APPLICATION.ERRORS.EMPTY_TITLE'
      );
    }

    if (!form.content) {
      return MessageService.error(
        'APPLICATION.ERRORS.EMPTY_CONTENT'
      );
    }

    const data: models.WorshipContent = {
      title: form.title,
      author: form.author,
      reference: form.reference,
      content: form.content,
      number: form.number,
      translation: form.translation,
      type: form.type,
      ...file && {
        file: {
          id: file.uid,
          name: file?.name,
          type: 4,
          base64: file?.base64,
          extension: file?.extension,
          isPrivate: false,
        },
      },
    };

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(WorshipContentActions.editWorshipContent(params.id, data, 'PRAYER'));
    } else {
      dispatch(WorshipContentActions.addWorshipContent(data, 'PRAYER'));
    }
  };

  return (
    <div className="cult-details">
      <Container fluid className="details">
        <Row>
          <Col>
            <PanelContentBreadcrumb
              items={[
                {
                  title: translate('PAGES.PANEL.PRAYER.REPORT.PAGE_TITLE'),
                  url: getRouteStackPath('WORSHIP', 'PRAYER_REPORT'),
                  active: true,
                },
                {
                  title: translate(
                    `PAGES.PANEL.PRAYER.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                    }`
                  ),
                  active: false,
                },
              ]}
            />
          </Col>
        </Row>
      </Container>
      <Row>
        <Col>
          <div className="details__title">
            <h3 className="details__title__text">
              {translate(
                `PAGES.PANEL.PRAYER.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                }`
              )}
            </h3>
            <p className="details__title__description">
              {translate(
                `PAGES.PANEL.PRAYER.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                }`
              )}
            </p>
          </div>
        </Col>
        <AdvancedForm onSubmit={onFormSubmit}>
          <div className="details__form">
            <Row>
              <Col md={6}>
                <AdvancedInput
                  onChange={(value: string | null) => onFormChange('title', value)}
                  value={form.title}
                  label={translate(
                    'PAGES.PANEL.PRAYER.DETAILS.ADD.LABELS.TITLE'
                  )}
                />
              </Col>
              <Col md={6}>
                <AdvancedInput
                  value={form.author}
                  onChange={(value: string | null) => onFormChange('author', value)}
                  label={translate(
                    'PAGES.PANEL.PRAYER.DETAILS.ADD.LABELS.AUTHOR'
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <AdvancedInput
                  onChange={(value: string | null) => onFormChange('reference', value)}
                  value={form.reference}
                  label={translate(
                    'PAGES.PANEL.PRAYER.DETAILS.ADD.LABELS.REFERENCE'
                  )}
                />
              </Col>
              <Col md={6}>
                <AdvancedInput
                  value={form.translation}
                  onChange={(value: string | null) => onFormChange('translation', value)}
                  label={translate(
                    'PAGES.PANEL.PRAYER.DETAILS.ADD.LABELS.TRANSLATION'
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <AdvancedInput
                  onChange={(value: string | null) => onFormChange('number', value)}
                  value={form.number}
                  type="number"
                  label={translate(
                    'PAGES.PANEL.PRAYER.DETAILS.ADD.LABELS.NUMBER'
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <br/>
                <p className="details__title__description">
                  {translate(
                    'PAGES.PANEL.PRAYER.DETAILS.ADD.LABELS.CONTENT'
                  )}
                </p>
                <AdvancedTextInput
                  id="prayer-form"
                  initialValue={initialValuesContent}
                  onChange={(val: string) => onFormChange('content', val)}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6} className="text-center">
                <AdvancedUploader
                  onChange={setFile}
                  hidePreview
                  isPdf
                  defaultImage={
                    file?.base64 ||
                    (form &&
                      form.file &&
                      form.file.url)
                  }
                  value={
                    file?.base64 ||
                    (form &&
                      form.file &&
                      form.file.url)
                  }
                >
                  <AdvancedButton
                    text={getPagetType() === PAGE_TYPE.ADD
                      ? translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.ADD.LABELS.FILE_URL')
                      : translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.EDIT_PDF')}
                    endIcon={<CloudUploadOutlined />}
                  />
                </AdvancedUploader>
              </Col>
              {pageType === PAGE_TYPE.EDIT && form.file && (
                <Col lg={6}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://docs.google.com/gview?url=${form.file && form.file.url && form.file.url.split('?')[0]}&embedded=true`}
                  >
                    <AdvancedButton
                      text={translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.BUTTON_LINK')}
                      endIcon={<LinkOutlined />}
                    />
                  </a>
                </Col>
              )}
              <Col lg={6}>
                {file && (
                  <iframe src={file.base64} style={{ width: '500px', height: '400px', marginTop: '20px', marginBottom: '20px' }} title="pdf_preview" />
                )}
              </Col>
            </Row>

            <div className="details__form__actions">
              <Row className="details__form__actions__row">
                <Col md="auto">
                  <Link
                    to={getRouteStackPath('WORSHIP', 'PRAYER_REPORT')}
                  >
                    <AdvancedButton
                      variant="text"
                      text={translate('SHARED.BACK')}
                      startIcon={<LeftOutlined />}
                    />
                  </Link>
                </Col>
                <Col md="auto">
                  <AdvancedButton
                    type="submit"
                    text={translate('SHARED.SAVE')}
                    endIcon={<PlusCircleOutlined />}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </AdvancedForm>
      </Row>
    </div>
  );
};

export default LiturgyDetails;
